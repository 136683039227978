.theme {
  width: 415px;
  height: 300px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.theme:hover {
  transform: scale(1.1); /* Zoom in on hover */
}

.theme h3 {
  text-align: center;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.selections{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
}
