.themes {
  animation: themes-appear 1s ease-out forwards;
}

.themesError {
  text-align: center;
  color: red;
}

@keyframes themes-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.submit {
  position: sticky;
  bottom: 2em;
  left: 108em;
  display: inline-block;
  height: 4rem;
  margin-top: 0px;
  overflow: hidden;
}

.submit div {
  font-size: 13px;
  font-style: italic;
  color: white;
}

.incorrect {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 2rem;
  font-size: 16px;
}

.correct {
  background-color: #1363c6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 2rem;
  font-size: 16px;
}

.disabled {
  background-color: #dc3545;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: auto;
}
